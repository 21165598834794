export const Paths = {
  home: '/',
  employeeAdd: '/employee/add',
  employeeEdit: '/employee/edit',
  commentEdit: '/comment/edit',
  usersEdit: '/users/edit',
  employee: '/employee',
  status: '/status',
  login: '/login',
  register: '/register',
  phone: 'tel:'
} as const;