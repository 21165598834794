import React from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../app/store";

import {toggleValue} from "../../features/selectedGlobalCategory/selectedGlobalCategorySlice"

import "./index.css";

const GlobalCategoriasSlider = () => { 
    const dispatch: AppDispatch = useDispatch();
    const value = useSelector((state: RootState) => state.selectedGlobalCategory.value);
    
    const onClick = (selectedValue: string) => {
        dispatch(toggleValue(selectedValue));
      };

  return (
    <div
      className="no-scrollbar"
      style={{
        display: "block",
        overflowX: "scroll",
        whiteSpace: "nowrap",
        width: "100%",
        marginBottom: 16,
      }}
    >
      <div
        className="no-scrollbar"
        style={{
          display: "inline-block",
          marginRight: 8,
          whiteSpace: "nowrap",
        }}
      >
        <Button
          size="large"
          type={value === "Boats"? "primary" : undefined}
          style={{ margin: 2 }}
          onClick={() => onClick("Boats")}
        >
          Boats
        </Button>

        <Button
          size="large"
          type={value === "Watersports"? "primary": undefined}
          style={{ margin: 2 }}
          onClick={() =>onClick("Watersports")}
        >
          Watersports
        </Button>

        <Button
          size="large"
          type={value === "Air"? "primary" : undefined}
          style={{ margin: 2 }}
          onClick={() => onClick("Air")}
        >
          Air
        </Button>

        <Button
          size="large"
          type={value === "Wheels"? "primary" : undefined}
          style={{ margin: 2 }}
          onClick={() => onClick("Wheels")}
        >
          Wheels
        </Button>

      </div>{" "}
    </div>
  );
}

export default GlobalCategoriasSlider;
