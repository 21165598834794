import { User } from "@prisma/client";
import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "../../app/serivices/auth"; // Adjust the path
import { RootState } from "../../app/store";

interface InitialState {
  users: User[] | null;
}

const initialState: InitialState = {
  users: null,
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logout: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.getAllUsers.matchFulfilled, (state, action) => {
        state.users = action.payload;
      })
  },
});

export default slice.reducer;

export const selectUsers = (state: RootState) =>
  state.users.users;
