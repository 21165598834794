import { useState } from "react";
import { useNavigate, Link, useParams, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../app/store";
import {
  Space,
  Divider,
  Modal,
  Flex,
  Spin,
  Typography,
  FloatButton,
} from "antd";
import { CustomButton } from "../../components/custom-button";
import { Paths } from "../../paths";
import {
  useGetEmployeeQuery,
  useRemoveEmployeeMutation,
} from "../../app/serivices/employees";
import { Layout } from "../../components/layout";
import { isErrorWithMessage } from "../../utils/is-error-with-message";
import { ErrorMessage } from "../../components/error-message";
import { selectUser } from "../../features/auth/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faComment,
  faPenToSquare,
  faPhone,
  faStar,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { getRideCategoria, getRideTypeName } from "../../utils/getRideTypes";
import { addOrRemoveRides } from "../../features/favoritSlice/favoritSlice";
import { isMobile } from "../../utils/isMobail";

export const Employee = () => {
  const dispatch: AppDispatch = useDispatch();
  const favoriteArray = useSelector(
    (state: RootState) => state.favoritedRides.favoritedRides
  );
  const id = useParams<{ id: string }>();
  const comments = useSelector((state: RootState) => state.comments.comments);

  const commentObject = comments.find((comment) => comment.id === id.id);
  const currentComment = commentObject?.description;

  const navigate = useNavigate();
  const [error, setError] = useState("");
  const params = useParams<{ id: string }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, isLoading } = useGetEmployeeQuery(params.id || "");
  const [removeEmployee] = useRemoveEmployeeMutation();
  const user = useSelector(selectUser);
  const { Title } = Typography;
  const itIsMobaileView = isMobile();

  const isAgent = useSelector((state: RootState) => state.typeUser.isAgent);
  const isProvider = useSelector((state: RootState) => state.typeUser.isProvider);
  const isSuperAdmin = useSelector((state: RootState) => state.typeUser.isSuperAdmin);

  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="center"
        style={{ height: "100vh", width: "100%" }}
      >
        <Spin size="large" />
      </Flex>
    );
  }

 

  if (!data) {
    return <Navigate to="/" />;
  }

  const setToFavoriteList = (id: string) => {
    dispatch(addOrRemoveRides(id));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteUser = async () => {
    hideModal();

    try {
      await removeEmployee(data.id).unwrap();

      navigate(`${Paths.status}/deleted`);
    } catch (err) {
      const maybeError = isErrorWithMessage(err);

      if (maybeError) {
        setError(err.data.message);
      } else {
        setError("Uncnoun error");
      }
    }
  };

  return (
    <Layout>
      <div
        style={{
          width: "100%",
          aspectRatio: "24 / 9",
          backgroundColor: "rgba(29, 29, 29, 0.8)",
          marginTop: -64,
        }}
      >
        <img
          src={
            data.rideFoto !== null
              ? data.rideFoto
              : "https://source.unsplash.com/weekly?boats"
          }
          alt="Description"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            display: "block",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          paddingBottom: 64,
          padding: 16,
        }}
      >
        <div style={{ width: "100%", maxWidth: 800 }}>
          <Title level={2} style={{ paddingTop: 12 }}>
            {`Information about ride ${data.rideName}`}
          </Title>

          <Title level={3} style={{ paddingTop: 12 }}>
            A remainder for myself
          </Title>
          <p style={{ fontWeight: 800, fontSize: 16 }}>{currentComment}</p>
          <Divider />

          <Title level={3}>Rider type</Title>
          <p>{getRideTypeName(data.rideType || "")}</p>
          <Divider />
          <Title level={3}>Start point</Title>
          <p>{data.startPoints || ""}</p>
          <Divider />
          <Title level={3}>Categoria</Title>
          <p>{getRideCategoria(data.rideType || "", data.categorias || "")}</p>
          <Divider />
          <Title level={3}>Ride Name</Title>
          <p>{data.rideName}</p>
          <Divider />
          <Title level={3}>Description</Title>
          <p>{data.description}</p>
          <Divider />
          <Title level={3}>Google Map Link</Title>
          <p>{data.googleMapLink}</p>
          <Divider />
          <Title level={3}>Phone</Title>
          <p style={{ fontSize: 20, fontWeight: 700 }}>{data.phone}</p>

          {user?.id === data.userId || isSuperAdmin ? (
            <>
              <Divider orientation="left">Acton</Divider>
              <Space>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <Link to={`/`}>
                    <CustomButton
                      shape="round"
                      type="default"
                      icon={<FontAwesomeIcon icon={faChevronLeft} />}
                    >
                      Go back
                    </CustomButton>
                  </Link>

                  {(isSuperAdmin || isProvider) && (
                    <>
                      <Link to={`/employee/edit/${data.id}`}>
                        <CustomButton
                          shape="round"
                          type="default"
                          icon={<FontAwesomeIcon icon={faPenToSquare} />}
                        >
                          Edit
                        </CustomButton>
                      </Link>
                      <CustomButton
                        shape="round"
                        danger
                        onClick={showModal}
                        icon={<FontAwesomeIcon icon={faTrash} />}
                      >
                        Remove
                      </CustomButton>
                    </>
                  )}
                </div>
              </Space>
            </>
          ) : (
            <>
              <Divider orientation="left">Acton</Divider>
              <Space>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "flex-start",
                  }}
                >
                  <Link to={`/`}>
                    <CustomButton
                      shape="round"
                      type="default"
                      icon={<FontAwesomeIcon icon={faChevronLeft} />}
                    >
                      Go back
                    </CustomButton>
                  </Link>
                  {isAgent && (
                    <>
                      <Link to={`/comment/edit/${data.id}`}>
                        <CustomButton
                          shape="round"
                          type="default"
                          icon={<FontAwesomeIcon icon={faComment} />}
                        >
                          Comment
                        </CustomButton>
                      </Link>

                      <CustomButton
                        shape="round"
                        onClick={() => setToFavoriteList(data.id)}
                        icon={
                          <FontAwesomeIcon
                            color={
                              favoriteArray.includes(data.id)
                                ? "yellow"
                                : "white"
                            }
                            icon={faStar}
                          />
                        }
                      >
                        Favorite
                      </CustomButton>

                      {itIsMobaileView && (
                        <a href={`tel:${data.phone}`}>
                          <FloatButton
                            icon={<FontAwesomeIcon icon={faPhone} />}
                            type="primary"
                            style={{ right: 20, bottom: 120 }}
                          />
                        </a>
                      )}
                    </>
                  )}
                  {isAgent && itIsMobaileView && (
                    <Link to={`${Paths.phone}${data.phone}`}>
                      <CustomButton
                        type="primary"
                        shape="round"
                        icon={<FontAwesomeIcon icon={faPhone} />}
                      >
                        Call
                      </CustomButton>
                    </Link>
                  )}
                </div>
              </Space>
            </>
          )}
          <ErrorMessage message={error} />
          <Modal
            title="Confirm remove"
            open={isModalOpen}
            onOk={handleDeleteUser}
            onCancel={hideModal}
            okText="Confirm"
            cancelText="Cancel"
          >
            Do you really want to remove the boat?
          </Modal>
        </div>
      </div>
    </Layout>
  );
};
