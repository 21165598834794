import { Employee } from "@prisma/client";
import { Flex, Row, Spin } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditEmployeeMutation,
  useGetEmployeeQuery,
} from "../../app/serivices/employees";
import { EmployeeForm } from "../../components/employee-form";
import { Layout } from "../../components/layout";
import { Paths } from "../../paths";
import { isErrorWithMessage } from "../../utils/is-error-with-message";

export const EditEmployee = () => {
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const [error, setError] = useState("");
  const { data, isLoading } = useGetEmployeeQuery(params.id || "");
  const [editEmployee] = useEditEmployeeMutation();
  let isNewRide = true;
  let isAvailable = true;
  let isBlocked = false;


  if (isLoading) {
    return (
      <Flex
        align="center"
        justify="center"
        style={{ height: "100vh", width: "100%" }}
      >
        <Spin size="large" />
      </Flex>
    );
  }

  

  const handleEditUser = async (employee: Employee) => {
    try {
      const editedEmployee = {
        ...data,
        ...employee,
      };

      await editEmployee(editedEmployee).unwrap();

      navigate(`${Paths.status}/created`);
    } catch (err) {
      const maybeError = isErrorWithMessage(err);

      if (maybeError) {
        setError(err.data.message);
      } else {
        setError("Unknown error");
      }
    }
  };

  return (
    <Layout>
      <Row align="middle" justify="center" style={{ margin: 16 }}>
        <EmployeeForm
          onFinish={handleEditUser}
          title="Edit ride"
          employee={data}
          btnText="Save"
          btnTextCancel="Reset"
          btnTextGoBack="Go back"
          error={error}
          isAvailable={isAvailable} 
          rideType={data?.rideType || ''}            
          isNewRide={isNewRide}
          isBlocked={isBlocked}
        
          pageName="Edit-emploee"
        />
      </Row>
    </Layout>
  );
};
