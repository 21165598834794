export const boatsTypes = [
  {
    key: "01",
    value: "01",
    name: "Boat Normal",
  },
  {
    key: "02",
    value: "02",
    name: "Boat big",
  },
  {
    key: "03",
    value: "03",
    name: "Boat small",
  },
];


export const ridesTypes = [
  {
    key: "01",
    value: "01",
    name: "Boats",
    startPoints: [
      {
        key: "01",
        value: "01",
        name: "Puerto Colon",
      },
      {
        key: "02",
        value: "02",
        name: "Las Galletas",
      },
      {
        key: "03",
        value: "03",
        name: "Los Cristianos",
      }
    ],
    categorias: [
      {
        key: "101",
        value: "101",
        name: "Catamarans",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702750909/immyrgvqp4puutijtnsa.png",
      },
      {
        key: "102",
        value: "102",
        name: "Sailboat",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702751076/zmxq2fqbe2vxdavv8z6b.png",
      },
      {
        key: "103",
        value: "103",
        name: "Motor yachts",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702751183/e6f4vxgocrmj4t2xshff.png",
      },
      {
        key: "104",
        value: "104",
        name: "Fishing",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702751321/iypcwhtqogbfpcfdj1cf.png",
      },
      {
        key: "105",
        value: "105",
        name: "Rent a boat",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702751483/kw2fnq8r8zlh4wnzn3dj.png",
      },

      {
        key: "106",
        value: "106",
        name: "Submarine",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702751639/ife1lexkmcrntsotodfq.png",
      },
      {
        key: "107",
        value: "107",
        name: "Other",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702751792/y4urzxmjkqs9gep9aomg.png",
      },
    ],
  },
  {
    key: "02",
    value: "02",
    name: "Watersports",
    startPoints: [
      {
        key: "01",
        value: "01",
        name: "Puerto Colon",
      },
      {
        key: "02",
        value: "02",
        name: "Las Galletas",
      },
      {
        key: "03",
        value: "03",
        name: "Los Cristianos",
      }
    ],
    categorias: [
      {
        key: "201",
        value: "201",
        name: "Jetski",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702751887/ktlhzrfl5xs3tov5gst4.png",
      },
      {
        key: "202",
        value: "202",
        name: "Diving",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702752042/yfvg8cud7zc9ju99nsu5.png",
      },
      {
        key: "203",
        value: "203",
        name: "Surfing",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702752215/sirrmsvavn9b4nc52rsq.png",
      },
      {
        key: "204",
        value: "204",
        name: "Other",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702752314/grna3bpqh991bpo18l1n.png",
      },
    ],
  },
  {
    key: "03",
    value: "03",
    name: "Air",
    startPoints: [
      {
        key: "01",
        value: "01",
        name: "Tenerife Sur",
      },
    ],
    categorias: [
      {
        key: "301",
        value: "301",
        name: "Paragliding",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702750758/q1wug7mtp193pqh9zgdk.png",
      },
      {
        key: "302",
        value: "302",
        name: "Helicopter",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702752385/c4gi9eopibdelesn3dqs.png",
      },
    ],
  },
  {
    key: "04",
    value: "04",
    name: "Wheels",
    startPoints: [
      {
        key: "01",
        value: "01",
        name: "Tenerife Sur",
      },
    ],
    categorias: [
      {
        key: "401",
        value: "401",
        name: "Stars",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1705329355/uw3dhfullemnakemcnwa.png",
      },
      {
        key: "402",
        value: "402",
        name: "Quads",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702752723/tjubm85wz4qhegslmmmq.png",
      },
      {
        key: "403",
        value: "403",
        name: "Buggy",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702752839/hhayom3gim5ah25xosum.png",
      },
      {
        key: "404",
        value: "404",
        name: "Other",
        foto: "https://res.cloudinary.com/db6a9pof6/image/upload/v1702753015/gze1uwlbaxgbpjgziizk.png",
      },
    ],
  },
];

export const allTimes: { [key: string]: string }[] = [
  { "01": "Extra 00:00" },
  { "02": "Extra 00:30" },
  { "03": "Extra 01:00" },
  { "04": "Extra 01:30" },
  { "05": "Extra 02:00" },
  { "06": "Extra 02:30" },
  { "07": "Extra 03:00" },
  { "08": "Extra 03:30" },
  { "09": "Extra 04:00" },
  { "10": "Extra 04:30" },
  { "11": "Extra 05:00" },
  { "12": "Extra 05:30" },
  { "13": "Extra 06:00" },
  { "14": "Extra 06:30" },
  { "15": "Extra 07:00" },
  { "16": "Extra 07:30" },
  { "17": "Extra 08:00" },
  { "18": "Extra 08:30" },
  { "19": "Extra 09:00" },
  { "20": "Extra 09:30" },
  { "21": "Extra 10:00" },
  { "22": "Extra 10:30" },
  { "23": "Extra 11:00" },
  { "24": "Extra 11:30" },
  { "25": "Extra 12:00" },
  { "26": "Extra 12:30" },
  { "27": "Extra 13:00" },
  { "28": "Extra 13:30" },
  { "29": "Extra 14:00" },
  { "30": "Extra 14:30" },
  { "31": "Extra 15:00" },
  { "32": "Extra 15:30" },
  { "33": "Extra 16:00" },
  { "34": "Extra 16:30" },
  { "35": "Extra 17:00" },
  { "36": "Extra 7:30" },
  { "37": "Extra 18:00" },
  { "38": "Extra 18:30" },
  { "39": "Extra 19:00" },
  { "40": "Extra 19:30" },
  { "41": "Extra 20:00" },
  { "42": "Extra 20:30" },
  { "43": "Extra 21:00" },
  { "44": "Extra 21:30" },
  { "45": "Extra 22:00" },
  { "46": "Extra 22:30" },
  { "47": "Extra 23:00" },
  { "48": "Extra 23:30" },
  { extra0: "Extra off" },
];

export const morning: { [key: string]: string }[] = [
  { "15": "07:00" },
  { "16": "07:30" },
  { "17": "08:00" },
  { "18": "08:30" },
  { "19": "09:00" },
  { "20": "09:30" },
  { "21": "10:00" },
  { "22": "10:30" },
  { "23": "11:00" },
  { "24": "11:30" },
  { m: "All morning" },
  { m0: "Morning off" },
];

export const afternoon: { [key: string]: string }[] = [
  { "25": "12:00" },
  { "26": "12:30" },
  { "27": "13:00" },
  { "28": "13:30" },
  { "29": "14:00" },
  { "30": "14:30" },
  { "31": "15:00" },
  { "32": "15:30" },
  { a: "All afternoon" },
  { a0: "Afternoon off" },
];

export const evening: { [key: string]: string }[] = [
  { "33": "16:00" },
  { "34": "16:30" },
  { "35": "17:00" },
  { "36": "17:30" },
  { "37": "18:00" },
  { "38": "18:30" },
  { "39": "19:00" },
  { "40": "19:30" },
  { "41": "20:00" },
  { "42": "20:30" },
  { "43": "21:00" },
  { "44": "21:30" },
  { "45": "22:00" },
  { "46": "22:30" },
  { "47": "23:00" },
  { e: "All evening" },
  { e1: "From 16:00 to 20:00" },
  { e0: "Evening off" },
];
